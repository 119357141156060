<template>
  <div class="wrap" id="moondaylist">
    <div class="header">
      <h2 class="st_title hidden-pad-down">
        <!-- <img src="@/assets/月亮日記/月亮日記標題圖片.svg" alt="" /> -->
        月亮日記列表
      </h2>
      <div class="filterBar">
        <label>
          <img class="serachIcon" src="@/assets/占星小鋪/search.svg" alt="" />
          <input
            type="text"
            placeholder="搜尋日記內容"
            v-model.trim="filterKey"
            @keyup.enter="searchHandler"
          />
        </label>
        <button class="filter_btn" @click="searchHandler">搜尋</button>
        <div class="moonlist__export" @click="openMoondayExport = true">
          <img src="../../assets/月亮日記/Download.svg" alt="" />
        </div>
        <div
          class="addMoonNote_btn"
          @click="addMoonNoteHandler()"
          v-if="nowConstellation"
        >
          <img src="@/assets/月亮日記/月亮日記加.svg" alt="" />
          <!-- <img
            class="hidden-pad-up"
            src="@/assets/月亮日記/月亮日記加加有顏色.svg"
            alt=""
          /> -->
        </div>
      </div>
    </div>
    <!---------------- To Edit Today-------------- -->
    <div class="toEditToday">
      <div class="toEditToday__main">
        <div class="toEditToday__main__line"></div>
        <div class="toEditToday__main__info">
          <div class="toEditToday__main__info__today">
            <img src="@/assets/月亮日記/月亮日記Logo.svg" alt="" />
            <span>月亮{{ nowConstellation }}日</span>
          </div>
          <div class="toEditToday__main__info__tip">
            <span>記錄當下的感受吧</span>
          </div>
        </div>
        <div class="toEditToday__main__line"></div>
      </div>
      <div class="toEditToday__notice">
        <div class="toEditToday__notice__pic">
          <img src="@/assets/提示燈泡.svg" alt="" />
        </div>
        <div class="toEditToday__notice__text">
          月亮每兩天半經過一個星座。特別提醒，觀察、記錄月亮日時，切勿鑽牛角尖，請用更寬闊的心體會喔。加油！持之以恆三個月以上。相信你將透過月亮日觀察，生活大小事的安排上都能「趨吉避凶」。
        </div>
      </div>
    </div>
    <!---------------- Ｍoon Tab-------------- -->
    <div class="moon__tab" v-show="moondayFilterTarget || List_filter.length">
      <swiper id="sw" :options="swiperOption" style="padding: 5px 5px">
        <swiper-slide
          class="moon__tab__item"
          :class="{
            activeTab: !moondayFilterTarget,
          }"
          @click.native="changeFilterHandler(null)"
        >
          <!-- <span class="iconfont interpret__icon">{{ astro.img }}</span> -->
          <img
            src="@/assets/月亮日記/月亮icon.svg"
            v-if="moondayFilterTarget"
          />
          <img src="@/assets/月亮日記/月亮icon_white.svg" v-else />

          <span class="moon__tab__item__text">全部</span>
        </swiper-slide>
        <swiper-slide
          v-for="astro in $Astro"
          :key="astro.en"
          class="moon__tab__item"
          :class="{
            activeTab:
              moondayFilterTarget && moondayFilterTarget.en === astro.en,
          }"
          @click.native="changeFilterHandler(astro)"
        >
          <span class="moon__tab__item__icon" v-html="astro.icon"></span>
          <span class="moon__tab__item__text">{{ astro.cn }}</span>
        </swiper-slide>
      </swiper>
    </div>
    <!---------------- Moon Statistics-------------- -->
    <MoondayStatistics
      v-show="List_filter.length"
      v-if="statisticsReady"
      :statistics="statistics"
    />
    <!---------------- Moon List Option-------------- -->
    <div class="moon_list_option" v-show="List_filter.length">
      <div class="moon_list_option_sort" @click="sortHandler()">
        <div class="moon_icon" :class="{ sorting: orderWay === 'ASC' }">
          <img src="../../assets/月亮日記/月亮日記排序.svg" alt="" />
        </div>
        {{ orderWay === "ASC" ? "舊到新" : "新到舊" }}
      </div>
    </div>
    <!-- 列表6/27改成沒有區分  寬度788-->
    <div class="moon_list_group" v-if="List_filter.length">
      <template v-for="item in List_filter">
        <div class="moondayCard" @click="toDetail(item)" :key="item.MoonNoteID">
          <div class="moondayCard__header">
            <div
              class="moondayCard__header__info"
              :class="{ infoWrap: splitMood(item.Moods).length > 2 }"
            >
              <div class="moondayCard__header__info__emotionGroup">
                <!-- emotionList -->
                <div
                  class="moondayCard__header__info__emotionGroup__emotion"
                  v-for="mood in splitMood(item.Moods)"
                  :key="mood"
                  :style="`background-color:${
                    emotionList.find((e) => e.id === mood).color
                  } `"
                >
                  <img
                    :src="emotionList.find((e) => e.id === mood).active_icon"
                  />
                </div>
              </div>
              <div class="moondayCard__header__info__text">
                <div class="moondayCard__header__info__text__title">
                  月亮{{ queryitemConstellationCN(item.Constellation) }}日
                  <div
                    class="moondayCard__header__info__text__title__platform"
                    v-show="nowloginPlatform !== item.Platform"
                  >
                    {{ item.Platform }}
                  </div>
                </div>
              </div>
            </div>
            <div class="moondayCard__header__option">
              <div class="option__group option__group--gapLarge">
                <div class="option__group__item">
                  <img
                    src="@/assets/icon-delete.svg"
                    title="刪除"
                    @click.stop="deleteMoonNoteHandler(item)"
                  />
                </div>
                <div class="option__group__item">
                  <img
                    src="@/assets/icon-edit.svg"
                    title="編輯"
                    @click.stop="editMoonNoteHandler(item)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="moondayCard__line">
            <span></span
            ><img src="@/assets/月亮日記/卡片分隔線.svg" alt="" /><span></span>
          </div>
          <div class="moondayCard__body">
            <div class="moondayCard__body__date">
              {{ `${item.Date} ${item.Time}` }}
            </div>
            <div
              class="moondayCard__body__content"
              v-html="replaceBr(highlightKeyword(item.Content))"
            ></div>
          </div>
        </div>
      </template>
    </div>
    <div class="moon_list_empty" v-else>
      <div class="moon_list_empty_title">開始寫你的第一篇日記吧！</div>
      <div
        class="moon_list_empty_btn"
        @click="addMoonNoteHandler()"
        v-if="nowConstellation"
      >
        <img src="@/assets/icon-edit_white.svg" alt="" />
        開始撰寫
      </div>
    </div>
    <!-- 分頁 -->
    <v-pagination
      v-if="List_filter.length > 0"
      v-model="pageIndex"
      class="my-4"
      :length="pageLength"
    ></v-pagination>

    <MoondayExport
      v-show="openMoondayExport"
      @close="openMoondayExport = false"
    ></MoondayExport>
  </div>
</template>

<script>
import TaiwanList from "@/js/taiwanList.json";
import MoondayExport from "@/components/月亮日記/MoondayExport";
import MoondayStatistics from "@/components/月亮日記/MoondayStatistics";
import moment from "moment";
import emotionList from "@/js/emotionList.js";
import MoondayFilter from "@/components/月亮日記/MoondayFilter";

export default {
  components: { MoondayExport, MoondayFilter, MoondayStatistics },
  data() {
    return {
      emotionList,
      statistics: {
        Happy: 0,
        Calm: 0,
        Frightened: 0,
        Sad: 0,
        Angry: 0,
      },
      statisticsReady: false,
      List_filter: [],
      TotalCount: 0,
      filterKey: "",
      //分頁
      pageSize: 10,
      privitePageIndex: 1,
      orderWay: "DESC", //ASC
      orderBy: "Date",
      //以下髒code是為了滿足月亮日記的需求= =
      TcAstralInfo: null,
      nowConstellation: "",
      openMoondayExport: false,
      //swiper
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 12,
        freeMode: true,
      },
    };
  },
  async created() {
    this.$store.commit("setLoading_force", true);
    if (!this.$store.state.userData.EnabledMoonNote) {
      let productId = this.$store.getters.productListMap["MoonNote"];
      this.$router.replace(`/store/detail/${productId}`);
      this.$store.commit("setLoading_force", false);
    } else {
      this.moondayFilterTarget = null;
      await this.fetchData();
      await this.getNowTcAstralInfo();
      await this.getParams();
      this.$store.commit("setLoading_force", false);
    }
  },
  computed: {
    nowloginPlatform() {
      return localStorage.loginMethods;
    },
    pageLength() {
      return Math.ceil(this.TotalCount / this.pageSize) || 1;
    },
    pageIndex: {
      set(value) {
        this.privitePageIndex = value;
        this.fetchData();
      },

      get() {
        return this.privitePageIndex || 1;
      },
    },
    nowUserData() {
      return this.$store.state.nowUserData;
    },
    moondayFilterTarget: {
      get() {
        return this.$store.getters.moondayFilterTarget;
      },
      set(Obj) {
        this.$store.commit("setMoondayFilterTarget", Obj);
      },
    },
  },
  methods: {
    async fetchData() {
      this.List_filter = [];
      try {
        let { data } = await this.$API.GET_MoonDayNoteList({
          OrderWay: this.orderWay,
          OrderBy: this.orderBy,
          PageSize: this.pageSize,
          PageIndex: this.pageIndex || 1,
          Content: `${
            this.moondayFilterTarget ? this.moondayFilterTarget.en : ""
          },${this.filterKey}`,
        });
        this.TotalCount = data.Data.TotalCount;
        this.List_filter = [...data.Data.Datas];
        this.fetchStatistics();
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchStatistics() {
      this.statisticsReady = false;
      try {
        let { data } = await this.$API.GET_MoonDayNoteMoodStatistics({
          Constellation: this.moondayFilterTarget
            ? this.moondayFilterTarget.en
            : "",
          Content: this.filterKey,
        });
        this.statistics = data.Data.MoodQuantity;
        this.statisticsReady = true;
      } catch (error) {
        console.error(error);
      }
    },
    searchHandler() {
      this.fetchData();
    },
    async addMoonNoteHandler() {
      let astro = this.$Astro.find((item) => item.cn === this.nowConstellation);
      this.$store.commit("setMoondayEdit", {
        MoonNoteID: "",
        Constellation_CN: this.nowConstellation,
        Constellation: astro.en,
        Content: "",
        MoodContent: "",
        Date: moment(new Date()).format("YYYY-MM-DD"),
        Time: moment(new Date()).format("HH:mm"),
      });
      this.$router.push("/moonday-create");
    },
    queryitemConstellationCN(constellation_en) {
      let astro = this.$Astro.find((item) => constellation_en === item.en);
      return astro.cn;
    },
    toDetail(item) {
      this.$router.push(`/moonday-note/${item.MoonNoteID}`);
    },
    changeFilterHandler(target) {
      this.moondayFilterTarget = target;
      this.fetchData();
    },
    replaceBr(str) {
      return str.replace(/\n/g, "<br />");
    },
    splitMood(mood) {
      return mood.split("|");
    },
    editMoonNoteHandler(moondayObj) {
      let Constellation_CN = this.queryitemConstellationCN(
        moondayObj.Constellation
      );
      this.$store.commit("setMoondayEdit", {
        ...moondayObj,
        Constellation_CN: Constellation_CN,
        Date: moment(new Date(moondayObj.Date)).format("YYYY-MM-DD"),
        Time: moondayObj.Time,
      });
      this.$router.push("/moonday-edit");
    },
    deleteMoonNoteHandler(moondayObj) {
      this.$messagePopup(
        "刪除月亮日記",
        "確定要刪除該月亮日記嗎？",
        "刪除",
        async () => {
          try {
            let { data } = await this.$API.DELETE_MoonDayNotes(
              moondayObj.MoonNoteID
            );
            if (data.Result) {
              this.$store.commit("update_toast", ["刪除成功"]);
              if (this.List_filter.length == 1 && this.pageIndex > 1)
                this.pageIndex--;
              this.fetchData();
            } else {
              this.$store.commit("update_toast", ["刪除失敗，請稍後再試"]);
            }
          } catch (error) {
            this.$store.commit("update_toast", ["刪除失敗，請稍後再試"]);
          }
        },
        true,
        true
      );
    },
    //以下髒code是為了滿足月亮日記的需求= =
    async getNowTcAstralInfo() {
      let Latitude = null;
      let Longitude = null;
      let TimeZone = 8;
      let format_time = moment().format("YYYY/MM/DD HH:mm");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // parseFloat(Longitude).toFixed(2)
            Latitude =
              position.coords.latitude > 0
                ? parseFloat(position.coords.latitude).toFixed(2) + "N"
                : parseFloat(position.coords.latitude).toFixed(2) + "S";
            Longitude =
              position.coords.longitude > 0
                ? parseFloat(position.coords.longitude).toFixed(2) + "E"
                : parseFloat(position.coords.longitude).toFixed(2) + "W";
            TimeZone = 0 - new Date().getTimezoneOffset() / 60;
          },
          (error) => {
            alert(
              "因「月亮日記」需要取得當前位置才能更精準的計算，請於瀏覽器彈窗選擇「允許/確定由本服務使用您的位置資訊」，若仍有問題請至您的裝置定位功能中開啟瀏覽器及Line的位置存取權。"
            );
          }
        );
      } else {
        alert(
          "提醒您，目前所使用的瀏覽器不支援取得當前座標地點，因此將無法正常使用「月亮日記」，若要使用此服務請換其他瀏覽器操作看看喔。"
        );
      }
      let Arg = {
        AstrolabeType: "SC",
        AstrolabeFileId: this.nowUserData.AstrolabeFileId,
        BirthTime: format_time,
        IsDaylight: this.nowUserData.IsDaylight,
        BirthLocation: {
          Place: this.findPlaceEngName(this.nowUserData.BirthPlace),
          Country: this.nowUserData.BirthCountry.split("(")[0],
          State: this.nowUserData.BirthState,
          City: this.nowUserData.BirthCity,
          CountryCode: this.findCountryCode,
          TimeZone,
          Latitude,
          Longitude,
        },
      };
      let res = await this.$API.POST_TcAstralInfo(Arg);
      if (res.Data.Result) {
        this.TcAstralInfo = res.Data.AstralInfo;
      } else {
        alert("國家地點找不到");
      }
    },
    findPlaceEngName(ChineseName) {
      let engName = TaiwanList.find((item) => {
        return item.text == ChineseName;
      });
      if (!engName) alert("找無該區");
      return engName ? engName.value : "Taipei";
    },
    //取得參數(需呼叫因為要刷新詳細參數內容、表格)
    async getParams() {
      let DetailNeed = this.$store.state["TimeLine"].mainPlanetList.concat(
        this.$store.state["TimeLine"].planetList
      );
      let detailNeed_fixed = [
        {
          Astral: "Sun",
          Degree: DetailNeed[0].val,
          Visable: DetailNeed[0].toggleSelect,
        },
        {
          Astral: "Moon",
          Degree: DetailNeed[1].val,
          Visable: DetailNeed[1].toggleSelect,
        },
        {
          Astral: "Mercury",
          Degree: DetailNeed[2].val,
          Visable: DetailNeed[2].toggleSelect,
        },
        {
          Astral: "Venus",
          Degree: DetailNeed[3].val,
          Visable: DetailNeed[3].toggleSelect,
        },
        {
          Astral: "Mars",
          Degree: DetailNeed[4].val,
          Visable: DetailNeed[4].toggleSelect,
        },
        {
          Astral: "Jupiter",
          Degree: DetailNeed[5].val,
          Visable: DetailNeed[5].toggleSelect,
        },
        {
          Astral: "Saturn",
          Degree: DetailNeed[6].val,
          Visable: DetailNeed[6].toggleSelect,
        },
        {
          Astral: "Uranus",
          Visable: DetailNeed[18].toggleSelect,
        },
        {
          Astral: "Neptune",
          Visable: DetailNeed[19].toggleSelect,
        },
        {
          Astral: "Pluto",
          Visable: DetailNeed[20].toggleSelect,
        },
        {
          Astral: "Ascendant",
          Visable: DetailNeed[21].toggleSelect,
        },
        {
          Astral: "Midheaven",
          Visable: DetailNeed[22].toggleSelect,
        },
        {
          Astral: "Chiron",
          Visable: DetailNeed[23].toggleSelect,
        },
        {
          Astral: "Ceres",
          Visable: DetailNeed[24].toggleSelect,
        },
        {
          Astral: "Pallas",
          Visable: DetailNeed[25].toggleSelect,
        },
        {
          Astral: "Juno",
          Visable: DetailNeed[26].toggleSelect,
        },
        {
          Astral: "Vesta",
          Visable: DetailNeed[27].toggleSelect,
        },
        {
          Astral: "NoNode",
          Visable: DetailNeed[28].toggleSelect,
        },
        {
          Astral: "SoNode",
          Visable: DetailNeed[29].toggleSelect,
        },
        {
          Astral: "Lilith",
          Visable: DetailNeed[30].toggleSelect,
        },
        {
          Astral: "Fortune",
          Visable: DetailNeed[31].toggleSelect,
        },
        {
          Astral: "Vertex",
          Visable: DetailNeed[32].toggleSelect,
        },
        {
          Astral: "EaPoint",
          Visable: DetailNeed[33].toggleSelect,
        },
        {
          Astral: "Descendant",
          Visable: DetailNeed[34].toggleSelect,
        },
        {
          Astral: "IC",
          Visable: DetailNeed[35].toggleSelect,
        },
        {
          Phase: "0",
          Degree: DetailNeed[7].val,
          Visable: DetailNeed[7].toggleSelect,
        },
        {
          Phase: "90",
          Degree: DetailNeed[8].val,
          Visable: DetailNeed[8].toggleSelect,
        },
        {
          Phase: "120",
          Degree: DetailNeed[9].val,
          Visable: DetailNeed[9].toggleSelect,
        },
        {
          Phase: "180",
          Degree: DetailNeed[10].val,
          Visable: DetailNeed[10].toggleSelect,
        },
        {
          Phase: "60",
          Degree: DetailNeed[11].val,
          Visable: DetailNeed[11].toggleSelect,
        },
        {
          Phase: "30",
          Degree: DetailNeed[12].val,
          Visable: DetailNeed[12].toggleSelect,
        },
        {
          Phase: "45",
          Degree: DetailNeed[13].val,
          Visable: DetailNeed[13].toggleSelect,
        },
        {
          Phase: "72",
          Degree: DetailNeed[14].val,
          Visable: DetailNeed[14].toggleSelect,
        },
        {
          Phase: "135",
          Degree: DetailNeed[15].val,
          Visable: DetailNeed[15].toggleSelect,
        },
        {
          Phase: "144",
          Degree: DetailNeed[16].val,
          Visable: DetailNeed[16].toggleSelect,
        },
        {
          Phase: "150",
          Degree: DetailNeed[17].val,
          Visable: DetailNeed[17].toggleSelect,
        },
      ];

      this.$API
        .POST_AstrolabeParams({
          SetupConfigs: detailNeed_fixed,
          AstralInfo: this.TcAstralInfo,
          AstrolabeType: "SC",
        })
        .then(({ Data }) => {
          this.nowConstellation = Data.ConstellationNodes[1].Constellation;
        });
    },
    sortHandler() {
      this.orderWay = this.orderWay === "ASC" ? "DESC" : "ASC";
      this.fetchData();
    },
    highlightKeyword(Content) {
      let keyword = this.filterKey;
      let reg = new RegExp(keyword, "g");
      let match = Content.match(reg);
      let copyContent = Content;
      if (match) {
        let targetIndex = Content.indexOf(keyword);
        if (targetIndex > 60) {
          Content =
            copyContent.slice(0, 40) +
            "..." +
            copyContent.slice(targetIndex - 10);
        }
        return Content.replace(reg, `<span class="keyword">${keyword}</span>`);
      } else {
        return Content;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#moondaylist {
  padding-top: 20px;
  width: 100%;
  max-width: 1094px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 16px 23px 0;
  }
  .header {
    display: flex;
    @include pad {
      flex-direction: column;
      align-items: center;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      @include mobile {
        margin: 0 auto;
        font-size: 18px;
        line-height: 26px;
        justify-content: center;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include mobile {
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .filterBar {
      margin: 42px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mobile {
        width: 100%;
        margin-top: 8px;
        margin-bottom: 24px;
        justify-content: flex-start;
      }
      label {
        width: 375px;
        height: 36px;
        position: relative;
        @include mobile {
          max-width: calc(100% - 172px);
          height: 36px;
        }
        .serachIcon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          @include mobile {
            left: 0;
          }
        }
        input,
        input:focus {
          width: 100%;
          height: 100%;
          background: #f0f2f4;
          box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
          border-radius: 25px;
          outline: none;
          padding: 8px 46px;
          @include mobile {
            box-shadow: none;
            padding: 8px 12px 8px 24px;
          }
        }
        input::placeholder {
          font-size: 14px;
          line-height: 20px;
          color: #d9d9d9;
        }
      }

      .filter_btn {
        flex-shrink: 0;
        @include btn-default(112px, 36px);
        border-radius: 100px;
        margin-left: 17px;
        @include mobile {
          @include btn-default(72px, 36px);
          margin-left: 0;
        }
      }
      .addMoonNote_btn {
        flex-shrink: 0;
        @include btn(40px, 40px, $theme-color-2, #fff);
        border-radius: 50%;
        margin-left: 30px;
        @include pad {
          margin-left: 12px;
          @include btn(36px, 36px, $theme-color-2, #fff);
        }
      }
    }
  }
  .moonlist__export {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #798da5;
    color: #fff;
    margin-left: 32px;
    padding: 4px;
    cursor: pointer;
    @include center;
    @include mobile {
      width: 36px;
      height: 36px;
      margin-left: 16px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .moon_list_option {
    max-width: 600px;
    margin: 54px auto 0;
    display: flex;
    align-items: centeer;
    @include pad {
      margin-top: 40px;
    }
    &_toStatistics,
    &_filter,
    &_sort {
      cursor: pointer;
      border-radius: 30px;
      background: #f0f2f4;
      box-shadow: 0px 6.6px 16.5px 0px rgba(0, 0, 0, 0.1);
      padding: 4px 12px 4px 4px;
      font-size: 15px;
      color: #868686;
      display: flex;
      align-items: center;
      @include pad {
        font-size: 13px;
      }
      .moon_icon {
        width: 28px;
        height: 28px;
        @include pad {
          width: 24px;
          height: 24px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &_toStatistics {
      padding-left: 12px;
      text-decoration: none;
    }
    &_filter {
      margin-left: auto;
      &.active {
        background-color: #798da5;
        color: #fff;
      }
    }
    &_sort {
      margin-left: auto;
      .moon_icon.sorting {
        transform: rotate(180deg);
      }
    }
  }
  .moon_list_group {
    max-width: 788px;
    margin: 24px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .row {
      width: 100%;
    }
  }
  .moon_list_empty {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    .moon_list_empty_title {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      color: #868686;
      letter-spacing: 1.2px;
      margin-bottom: 20px;
      @include mobile {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .moon_list_empty_btn {
      @include btn-default(176px, 46px);
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      color: #fff;
      @include center;
    }
  }
  //moon tab
  .moon__tab {
    max-width: 600px;
    margin: 24px auto 0;
    position: relative;
    @include align(flex-start, center);
    &__item {
      @include rect(48px, 72px, $theme-color-3, 24px);
      @include center;
      flex-direction: column;
      box-shadow: -1px -1px 4px 0 #ffffff, 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
      // transition: 0.2s;
      cursor: pointer;
      img {
        width: 32px;
        @include pad {
          width: 28px;
        }
      }
      &__icon {
        color: #ab956c;
        .iconfont {
          font-size: 28px;
          line-height: 28px;
          @include pad {
            font-size: 22px;
            line-height: 22px;
          }
        }
      }
      &__text {
        writing-mode: vertical-rl;
        color: #9b9b9b;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.56px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.activeTab {
        background-color: #ab956c;
        .moon__tab__item__icon {
          color: #ffffff;
        }
        .moon__tab__item__text {
          color: #ffffff;
        }
      }
    }
  }
}

//編輯今天
.toEditToday {
  max-width: 788px;
  margin: 30px auto 23px;
  @include mobile {
    margin: 8px auto 16px;
  }
  &__main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__line {
      flex-grow: 1;
      height: 1px;
      background-color: #ab956c;
    }
    &__info {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile {
        font-size: 16px;
      }
      &__today {
        display: flex;
        align-items: center;
        color: #ab956c;
        margin-bottom: 12px;
        @include mobile {
          margin-bottom: 4px;
        }
        img {
          width: 32px;
          height: 32px;
          @include mobile {
            width: 20px;
            height: 20px;
          }
        }
      }
      &__tip {
        color: #868686;
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          margin-left: 4px;
          cursor: pointer;
          @include mobile {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  &__notice {
    display: flex;
    margin-top: 28px;
    &__pic {
      margin-right: 12px;
      img {
        width: 32px;
        height: 32px;
        @include mobile {
          width: 20px;
          height: 20px;
        }
      }
    }
    &__text {
      font-size: 18px;
      font-weight: 400;
      color: #868686;
      @include mobile {
        font-size: 13px;
      }
    }
  }
}

//月亮日記卡片
.moondayCard {
  width: 600px;
  min-height: 204px;
  border-radius: 20px;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  padding: 24px 28px;
  margin: 0 auto 28px;
  cursor: pointer;
  @include mobile {
    width: 100%;
    min-height: 152px;
    border-radius: 8px;
    padding: 18px 16px;
    margin: 0 auto 24px;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__info {
      display: flex;
      align-items: flex-start;
      &.infoWrap {
        flex-direction: column-reverse;
        .moondayCard__header__info__emotionGroup {
          margin-top: 8px;
        }
      }
      &__emotionGroup {
        display: flex;
        &__emotion {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          margin-right: 8px;
          @include center;
          @include mobile {
            width: 28px;
            height: 28px;
          }
          img {
            width: 21px;
            height: 21px;
            @include mobile {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      &__text {
        &__title {
          color: #ab956c;
          font-size: 20px;
          line-height: 36px;
          display: flex;
          @include mobile {
            font-size: 16px;
            line-height: 28px;
          }
          &__platform {
            margin-left: 8px;
            margin-right: 8px;
            margin-top: 9px;
            height: 18px;
            padding: 2px 12px;
            background-color: #f0f2f4;
            box-shadow: 2px 2px 4px #fff, inset 4px 4px 10px rgba(0, 0, 0, 0.08);
            border-radius: 50px;
            font-size: 10px;
            color: #17445c;
            background-color: #f0f2f4;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            @include mobile {
              margin-top: 4px;
              font-size: 10px;
            }
          }
        }
        &__date {
          color: #9b9b9b;
          font-size: 16px;
          font-weight: 400;
          line-height: 18px;
          @include mobile {
            font-size: 12px;
          }
        }
      }
    }
  }
  &__line {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 12px 0 8px;
    span {
      height: 1px;
      background-color: #d3d3d3;
      display: flex;
      flex-grow: 1;
    }
  }
  &__body {
    &__date {
      color: #9b9b9b;
      margin-bottom: 12px;
      font-size: 16px;
      @include mobile {
        font-size: 12px;
        margin-bottom: 8px;
      }
    }
    &__content {
      color: #9b9b9b;
      text-align: justify;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
      word-break: break-all;
      @include mobile {
        font-size: 14px;
        line-height: 18px;
      }
      .keyword {
        display: inline-block;
        background-color: #c6dbf9;
      }
    }
  }
}
</style>
